(function(botbox) {
    $('body').on('click', '#show-help-btn', function(e) {
        e.preventDefault();
        botbox.alert({
            message: `
                <h4>Thumbnail</h4>
                <p>Select an image that will be shown as a background image of a tab box. Click on the <b>Choose file</b> to select an image to upload. If you do not upload an image, tab box will have a transparent background.</p>
                </br>
                <h4>Page name</h4>
                <p>For the right section configuration, insert a page name that the application will use for redirection.
                Possible page names:
                    <ul>
                        <li>Lucky six page name: <b>lucky-six</b></li>
                        <li>Casino page name: <b>casino</b></li>
                        <li>Keno page name: <b>keno</b></li>
                        <li>Lotto page name: <b>lotto</b></li>
                    </ul>
                For the bottom section configuration, insert sport id or 'live' if you want the tab box to lead to live page.
                </p>
                </br>
                <h4>Translation key</h4>
                <p>Insert a translation KEY that will be used with terminal section and prefix "landing_". Example: "terminal.landing_58" or "terminal.landing_keno" (you are inserting "58" or "keno").</p>
                </br>
                <h4>Checkbox</h4>
                <p>Select this checkbox if the page is in development and not yet ready to be used but want users to know its coming soon. Page will not be accessible and will have a "Coming soon" badge.</p>
            `,
            title: 'Instructions',
            className: 'u-center-box',
        })
    });
})(window.bootbox);