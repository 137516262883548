var placeholderCounter = 1;
var url = window.location.href;
var values = {};

if (!$('#is_non_rest_provider').is(":checked")) {
  $('#non-rest-provider-params').hide();
}

$('#is_non_rest_provider').change(function() {
  if($(this).is(":checked")) {
    $('#non-rest-provider-params').show('fast');

    return;
  }

  $('#non-rest-provider-params').hide('fast');
});

// $('#payments-form-create #placeholders-section').on('click', '#add-placeholder', function(e) {
//   e.preventDefault();

//   placeholderCounter = placeholderCounter + 1;
//   addPlaceholdersInput();
// });

// $('#payments-form-edit #placeholders-section').on('click', '#add-placeholder', function(e) {
//   e.preventDefault();

//   placeholderCounter = placeholderCounter + 1;
//   addPlaceholdersInput();
// });

// $('body').on('click', '.glyphicon-tooltips', function(e) {
//   let elementId = $(this).attr('id').split('-')

//   if (elementId[2] && $(`#placeholder-${elementId[2]}`)) {
//     $(`#placeholder-${elementId[2]}`).remove();
//   }
// });

// function addPlaceholdersInput() {
//   $('#inputs-key-value').append(`<div class="input-group display-flex-align margin-bottom15" id="placeholder-${placeholderCounter}">
//     <input id="key-${placeholderCounter}" type="text" class="form-control key-gray-color" placeholder="Key">
//     <input id="value-${placeholderCounter}"type="text" class="form-control" placeholder="value">
//     <span style="padding: 7px" id="remove-placeholder-${placeholderCounter}" class="glyphicon glyphicon-tooltips glyphicon-remove" aria-hidden="true"></span>
//   </div>`);
// }

// $('body').on('submit', '#payments-form-create', function(e) {
//   e.preventDefault();
//   var $this = $(this);
//   let websiteId = $('#website-id').attr('data-id');
//   var token = $('#website-id').data('token');
//   var isMobile = $('#website-id').data('mobile');

//   var form = new FormData(this);
//   for(i = 0; i<placeholderCounter; i++) {
//     if ($(`#key-${i + 1}`).val() && $(`#value-${i + 1}`).val()) {
//       values[$(`#key-${i + 1}`).val()] = $(`#value-${i + 1}`).val();
//     }
//   }
//   placeholders = JSON.stringify(values);

//   $.ajax({
//     url : $this.attr('action'),
//     type: 'post',
//     data: form,
//     processData: false,
//     contentType: false,
//     success: function(response) {
//       if (response && response.id) {
//         updatePlaceHolders(websiteId, response, token, placeholders, isMobile);
//       }
//     },
//     error: function(error) {
//       $errors = error.responseJSON.errors;
//       errorsHtml = '';

//       $.each($errors, function(key, value) {
//         errorsHtml += '<li>' + value[0] + '</li>';
//       });

//       $('#payments-create-fail').css('display', 'block');
//       $('#payments-create-fail').html(errorsHtml);
//       $('html, body').animate({scrollTop:0}, 'slow');
//     }
//   });
// });

// $(document).ready(function() {
//   let placeholders = $('#payments-form #placeholders').val();
//     if ($('#placeholders').length){   // check if div with id 'games-included' exist
//       let variableFromLaravel=$("#placeholders").text();
//       if (variableFromLaravel) {
//         placeholders = JSON.parse(variableFromLaravel);
//         _.each(placeholders , (value, key) => {
//           values[key] = value;
//           $('#inputs-key-value').append(`<div class="input-group display-flex-align margin-bottom15" id="placeholder-${placeholderCounter}">
//             <input id="key-${placeholderCounter}" type="text" value="${key}" class="form-control key-gray-color" placeholder="Key">
//             <input id="value-${placeholderCounter}"type="text" value="${value}" class="form-control" placeholder="value">
//             <span style="padding: 7px" id="remove-placeholder-${placeholderCounter}" class="glyphicon glyphicon-tooltips glyphicon-remove" aria-hidden="true"></span>
//           </div>`);
//           placeholderCounter ++;
//         });
//       }
//     }
// });

// function updatePlaceHolders(websiteId, response, token, placeholders, isMobile) {
//   $.ajax({
//     url : '/websites/'+ websiteId + '/payments/update-payments-placeholders' + '/' + response.id,
//     type: 'post',
//     dataType:'json',
//     data: {_method: 'post', _token :token, placeholders: placeholders, isMobile: isMobile},
//     success: function(response) {
//       values = {};
//       placeholderCounter = 1;
//       window.location.replace(
//         window.location.origin + `/websites/${websiteId}/payments?type=${response.type}&isMobile=${isMobile}`
//       );
//     },
//     error: function(data) {
//     }
//   });
// }

// $('body').on('submit', '#payments-form-edit', function(e) {
//   e.preventDefault();
//   var $this = $(this);
//   let websiteId = $('#website-id').attr('data-id');
//   var token = $('#website-id').data('token');
//   var isMobile = $('#website-id').data('mobile');
//   let values = {};

//   var form = new FormData(this);
//   for(i = 0; i<placeholderCounter; i++) {
//     if ($(`#key-${i + 1}`).val() && $(`#value-${i + 1}`).val()) {
//       values[$(`#key-${i + 1}`).val()] = $(`#value-${i + 1}`).val();
//     }
//   }
//   placeholders = JSON.stringify(values);

//   $.ajax({
//     url : $this.attr('action'),
//     type: 'post',
//     data: form,
//     processData: false,
//     contentType: false,
//     success: function(response) {
//       if (response && response.id) {
//         updatePlaceHolders(websiteId, response, token, placeholders, response.is_mobile)
//       }
//     },
//     error: function(error) {
//       console.log(error.responseJSON);
//       $errors = error.responseJSON.errors;
//       errorsHtml = '';

//       $.each($errors, function(key, value) {
//         errorsHtml += '<li>' + value[0] + '</li>';
//       });

//       $('#payments-update-fail').css('display', 'block');
//       $('#payments-update-fail').html(errorsHtml);
//       $('html, body').animate({scrollTop:0}, 'slow');
//     }
//   });
// });
