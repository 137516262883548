
$('#website-configuration-form').submit(function() {
    var $this = $(this);
    var form = new FormData($this[0]);
    $('#website-configuration-update-ok').css('display', 'none');
    $('#website-configuration-update-fail').css('display', 'none');
    $.ajax({
      url : $this.attr('action'),
      type: 'POST',
      data: form,
      processData: false,
      contentType: false,
      success: function(response) {
        $('#website-configuration-update-ok').css('display', 'block');
        $('html, body').animate({scrollTop:0}, 'slow');
      },
      error: function(error) {
        $errors = error.responseJSON.errors;
        errorsHtml = '';
        $.each($errors, function(key, value) {
  
          errorsHtml += '<li>' + value[0] + '</li>';
        });
  
        $('#website-configuration-update-fail').css('display', 'block');
        $('#website-configuration-update-fail').html(errorsHtml);
        $('html, body').animate({scrollTop:0}, 'slow');
      }
    });
  
    return false;
  });