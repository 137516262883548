let landingPageImageUrl;
let landingPageTabsCounter;

function presentImageInForm(input) {
    if (window.FileReader && window.File && window.FileList && window.Blob) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
                var $box = $(input).closest('.image-text-option');
                $box.find('.thumbnail').html('<img src="'+e.target.result+'">');
                $box.find('.upload-image-name').val(input.files[0]['name']);
              }
            reader.readAsDataURL(input.files[0]);
        }
    }
}

function handleLandingPageSectionInit(landingPageSectionTabs) {
    let topSectionConfiguration = JSON.parse(landingPageSectionTabs);

    if (topSectionConfiguration.tabs) {
        topSectionConfiguration = topSectionConfiguration.tabs;
    }

    _.each(topSectionConfiguration, parsedTab => {
        const inputProperties = formatInputPropertiesWithValues(landingPageTabsCounter, parsedTab);
    
        $('#landing-page-tabs-input').append(`
        <div class="panel panel-default image-text-option" id="landing-page-tab-${landingPageTabsCounter}">
            <div class="panel-heading">
                <span style="padding: 7px" id="remove-landing-page-tab-${landingPageTabsCounter}" class="glyphicon glyphicon-landing-page glyphicon-remove" aria-hidden="true"></span>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-2">
                        <div class="thumbnail">
                            <img src="${landingPageImageUrl}${parsedTab.thumbnail}"/>
                        </div>
                        <input type="file" name="landing-page-tab[${landingPageTabsCounter}][image]" class="landing-page-image" onchange="presentImageInForm(this)">
                    </div>
                    <div class="col-md-10">
                        <select class="form-control m-bot15" id="terminal-landing-page-dropdown-${landingPageTabsCounter}" name="landing-page-tab[${landingPageTabsCounter}][type]" style="margin-bottom: 10px;">
                            <option value="SPORT" ${parsedTab.type === 'SPORT' ? 'selected' : ''}>Sport</option>
                            <option value="REGION" ${parsedTab.type === 'REGION' ? 'selected' : ''}>Region</option>
                            <option value="LEAGUE" ${parsedTab.type === 'LEAGUE' ? 'selected' : ''}>League</option>
                            <option value="PAGE" ${parsedTab.type === 'PAGE' ? 'selected' : ''}>Page</option>
                        </select>

                        <div class="input-group">
                            <span class="input-group-addon" style="min-width: 85px"><span class="fa fa-image"></span></span>
                            <input class="form-control upload-image-name" name="landing-page-tab[${landingPageTabsCounter}][thumbnail]" placeholder="Thumbnail" type="text" value="${parsedTab.thumbnail}">
                        </div>

                        <div id="landing-page-item-input-properties-${landingPageTabsCounter}">
                            ${inputProperties}
                        </div>
                    </div>
                </div>
                <div style="display: flex; align-items: center; margin-top: 10px;">
                    <input style="margin-top: 0; margin-right: 10px;" name="landing-page-tab[${landingPageTabsCounter}][commingSoon]" placeholder="Thumbnail" ${ parsedTab.commingSoon ? 'checked': '' } type="checkbox" value="true">
                    <div> Should block page and show comming soon badge</div>
                </div>
                <input type="hidden" name="landing-page-tab[${landingPageTabsCounter}][id]" value="${parsedTab.id}">
            </div>
        </div>
        `);

        $(`#terminal-landing-page-dropdown-${landingPageTabsCounter}`).on('change', event => {
            const elementCounter = event.target.id.split('-').pop();
            const value = event.target.value;

            $(`#landing-page-item-input-properties-${elementCounter}`).html(formatInputProperties(elementCounter, value));
        })
    
        landingPageTabsCounter++;
    })
}

$(document).ready(function () {
    landingPageTabsCounter = 0;
    landingPageImageUrl = $('#landing-page-section-image-url-value').text();
    const landingPageSection = $('#landing-page-section-tabs-type-value').text();
    const landingPageSectionTabs = $('#landing-page-section-tabs-value').text();

    if (!landingPageSectionTabs) {
        return;
    }

    handleLandingPageSectionInit(landingPageSectionTabs);
});

  $('body').on('click', '#add-landing-page-top-section-tab', function (e) {
    e.preventDefault();
  
    landingPageTabsCounter++;
    addLandingPageTopSectionTab();
});

function addLandingPageTopSectionTab() {
    const inputProperties = formatInputProperties(landingPageTabsCounter, 'SPORT');
    
    $('#landing-page-tabs-input').append(`
    <div class="panel panel-default image-text-option" id="landing-page-tab-${landingPageTabsCounter}">
        <div class="panel-heading">
            <span style="padding: 7px" id="remove-landing-page-tab-${landingPageTabsCounter}" class="glyphicon glyphicon-landing-page glyphicon-remove" aria-hidden="true"></span>
        </div>
        <div class="panel-body">
            <div class="row">
                <div class="col-md-2">
                    <div class="thumbnail">
                        <img src=""/>
                    </div>
                    <input type="file" name="landing-page-tab[${landingPageTabsCounter}][image]" class="landing-page-image" onchange="presentImageInForm(this)">
                </div>
                <div class="col-md-10">
                    <select class="form-control m-bot15" id="terminal-landing-page-dropdown-${landingPageTabsCounter}" name="landing-page-tab[${landingPageTabsCounter}][type]" style="margin-bottom: 10px;">
                        <option value="SPORT">Sport</option>
                        <option value="REGION">Region</option>
                        <option value="LEAGUE">League</option>
                        <option value="PAGE">Page</option>
                    </select>

                    <div class="input-group">
                        <span class="input-group-addon" style="min-width: 85px"><span class="fa fa-image"></span></span>
                        <input class="form-control upload-image-name" name="landing-page-tab[${landingPageTabsCounter}][thumbnail]" placeholder="Thumbnail" type="text" value="">
                    </div>
                    <div id="landing-page-item-input-properties-${landingPageTabsCounter}">    
                        ${inputProperties}
                    </div>
                </div>
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px;">
                <input style="display: inline-block; margin-top: 0; margin-right: 10px;" name="landing-page-tab[${landingPageTabsCounter}][commingSoon]" placeholder="Thumbnail" type="checkbox" value="true">
                <div> Should block page and show comming soon badge</div>
            </div>
            <input type="hidden" name="landing-page-tab[${landingPageTabsCounter}][id]" value="${new Date().getTime()}">
        </div>
    </div>
    `);

    $(`#terminal-landing-page-dropdown-${landingPageTabsCounter}`).on('change', event => {
        const elementCounter = event.target.id.split('-').pop();
        const value = event.target.value;

        $(`#landing-page-item-input-properties-${elementCounter}`).html(formatInputProperties(elementCounter, value));
    })
}

function formatInputProperties(elementCounter, dropDownValue = '') {
    let inputProperties = '';
    
    if (dropDownValue === 'PAGE') {
        inputProperties += `
        <div class="input-group">
            <span class="input-group-addon" style="min-width: 85px"><span class="fa">Slug</span></span>
            <input class="form-control" placeholder="Page Url Slug" name="landing-page-tab[${elementCounter}][urlSlug]" type="text" value="" required>
        </div>
        <div class="input-group">
            <span class="input-group-addon" style="min-width: 85px"><span class="fa">Label</span></span>
            <input class="form-control" placeholder="Label Translation" name="landing-page-tab[${elementCounter}][label]" type="text" value="" required>
        </div>
        `;
    }

    if (dropDownValue === 'SPORT') {
        inputProperties += `
        <div class="input-group">
            <span class="input-group-addon" style="min-width: 85px"><span class="fa">Sport ID</span></span>
            <input class="form-control" placeholder="Sport ID" name="landing-page-tab[${elementCounter ?? landingPageTabsCounter}][sportId]" type="text" value="" required>
        </div>
    `;
    }

    if (dropDownValue === 'REGION') {
        inputProperties += `
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Sport ID</span></span>
                <input class="form-control" placeholder="Sport ID" name="landing-page-tab[${elementCounter}][sportId]" type="text" value="" required>
            </div>
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Region ID</span></span>
                <input class="form-control" placeholder="Region ID" name="landing-page-tab[${elementCounter}][regionId]" type="text" value="" required>
            </div>
        `;
    }

    if (dropDownValue === 'LEAGUE') {
        inputProperties += `
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Sport ID</span></span>
                <input class="form-control" placeholder="Sport ID" name="landing-page-tab[${elementCounter}][sportId]" type="text" value="" required>
            </div>
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Region ID</span></span>
                <input class="form-control" placeholder="Region ID" name="landing-page-tab[${elementCounter}][regionId]" type="text" value="" required>
            </div>
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">League ID</span></span>
                <input class="form-control" placeholder="League ID" name="landing-page-tab[${elementCounter}][leagueId]" type="text" value="" required>
            </div>
        `;
    }
    return inputProperties;
}

function formatInputPropertiesWithValues(counter, tab) {
    const type = tab.type;
    let inputProperties = '';
    
    if (type === 'PAGE') {
        inputProperties += `
        <div class="input-group">
            <span class="input-group-addon" style="min-width: 85px"><span class="fa">Slug</span></span>
            <input class="form-control" placeholder="Page Url Slug" name="landing-page-tab[${counter}][urlSlug]" type="text" value="${tab.urlSlug}" required>
        </div>
        <div class="input-group">
            <span class="input-group-addon" style="min-width: 85px"><span class="fa">Label</span></span>
            <input class="form-control" placeholder="Label Translation" name="landing-page-tab[${counter}][label]" type="text" value="${tab.label}" required>
        </div>
        `;
    }

    if (type === 'SPORT') {
        inputProperties += `
        <div class="input-group">
            <span class="input-group-addon" style="min-width: 85px"><span class="fa">Sport ID</span></span>
            <input class="form-control" placeholder="Sport ID" name="landing-page-tab[${counter}][sportId]" type="text" value="${tab.sportId}" required>
        </div>
    `;
    }

    if (type === 'REGION') {
        inputProperties += `
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Sport ID</span></span>
                <input class="form-control" placeholder="Sport ID" name="landing-page-tab[${counter}][sportId]" type="text" value="${tab.sportId}" required>
            </div>
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Region ID</span></span>
                <input class="form-control" placeholder="Region ID" name="landing-page-tab[${counter}][regionId]" type="text" value="${tab.regionId}" required>
            </div>
        `;
    }

    if (type === 'LEAGUE') {
        inputProperties += `
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Sport ID</span></span>
                <input class="form-control" placeholder="Sport ID" name="landing-page-tab[${counter}][sportId]" type="text" value="${tab.sportId}" required>
            </div>
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">Region ID</span></span>
                <input class="form-control" placeholder="Region ID" name="landing-page-tab[${counter}][regionId]" type="text" value="${tab.regionId}" required>
            </div>
            <div class="input-group">
                <span class="input-group-addon" style="min-width: 85px"><span class="fa">League ID</span></span>
                <input class="form-control" placeholder="League ID" name="landing-page-tab[${counter}][leagueId]" type="text" value="${tab.leagueId}" required>
            </div>
        `;
    }
    return inputProperties;
}

$('body').on('click', '.glyphicon-landing-page', function (e) {
  let elementId = $(this).attr('id').split('-')

  if (elementId[4] && $(`#remove-landing-page-tab-${elementId[4]}`)) {
    $(`#landing-page-tab-${elementId[4]}`).remove();
  }
});

$('body').on('submit', '#terminal-landing-page-configuration', function (e) {
    e.preventDefault();
    var $this = $(this);
    var form = new FormData($this[0]);

    for (var pair of form.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
    }

    let websiteId = $('input[name="website_id"').val();
    let landingPageSection = $('#landing-page-section-tabs-type-value').text();
    form.append('_method', 'post');
    form.append('section', landingPageSection);
    
    if (landingPageSection === 'terminal-landing-page-top-section') {
        form.append('topSectionType', $('#terminal-landing-page-dropdown').val());
    }

    $.ajax({
        url: `/terminals/${websiteId}/update-landing-page-section-tabs`,
        type: 'post',
        processData: false,
        contentType: false,
        data: form,
        success: function (response) {
          $('#website-configuration-update-ok').css('display', 'block');
          $('html, body').animate({scrollTop:0}, 'slow');
          $('#landing-page-section-tabs-value').text(JSON.stringify(response.data));
        },
        error: function (data) {
        }
    });
});